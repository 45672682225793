import React from "react";

const JerseySponsoLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="50pt"
    height="50pt"
    viewBox="0 0 1024.000000 1024.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M0 5120 l0 -5120 5120 0 5120 0 0 5120 0 5120 -5120 0 -5120 0 0
   -5120z m8226 4079 c19 -21 19 -103 19 -4079 0 -3976 0 -4058 -19 -4079 l-19
   -21 -1712 0 c-1462 0 -1714 2 -1731 14 -19 14 -19 46 -22 1863 -1 1016 0 1858
   3 1870 8 32 33 53 65 53 20 0 104 -45 257 -138 364 -221 413 -253 431 -281 16
   -23 17 -129 22 -1298 5 -1204 6 -1273 23 -1290 16 -16 41 -18 266 -18 231 0
   250 1 270 19 l21 19 -2 1426 -3 1426 -28 42 c-30 44 -80 77 -517 343 -102 62
   -223 136 -270 165 -47 29 -174 106 -283 171 -109 64 -211 133 -228 151 l-29
   34 0 1108 c0 1106 0 1108 21 1135 l20 26 1023 0 c908 0 1024 -2 1044 -16 l22
   -15 0 -946 c0 -867 -1 -948 -17 -965 -32 -36 -70 -23 -243 83 -405 248 -471
   291 -488 323 -14 27 -17 78 -20 370 -4 315 -5 339 -23 357 -18 18 -37 19 -253
   19 -215 0 -236 -2 -257 -19 l-24 -19 -3 -524 c-2 -287 0 -538 3 -556 5 -27 19
   -41 88 -86 45 -29 98 -61 117 -71 31 -16 174 -99 815 -477 94 -56 186 -110
   205 -121 48 -28 79 -57 98 -92 15 -27 17 -165 17 -1648 l0 -1619 24 -19 c21
   -18 42 -19 271 -19 l249 0 20 26 21 27 0 3269 0 3269 -25 24 -24 25 -2299 0
   -2299 0 -24 -26 -24 -26 0 -1246 c0 -969 -3 -1255 -13 -1283 -18 -53 -73 -99
   -212 -179 -69 -40 -198 -117 -286 -171 -172 -106 -205 -116 -240 -78 -19 21
   -19 68 -22 1868 -1 1015 0 1856 3 1868 3 12 12 29 21 37 14 14 310 16 3098 16
   l3083 0 19 -21z m-4096 -1359 c20 -20 20 -33 20 -3402 0 -3132 -1 -3383 -17
   -3400 -15 -17 -61 -18 -1059 -18 l-1044 0 -14 23 c-13 19 -15 250 -17 1787 -1
   1918 -4 1806 52 1856 13 12 138 90 277 173 139 84 274 165 299 181 56 36 88
   38 120 8 l23 -21 0 -1594 c0 -1564 0 -1593 19 -1612 29 -29 64 -33 307 -29
   202 3 224 5 243 22 l21 19 0 2989 0 2989 25 24 24 25 351 0 c337 0 351 -1 370
   -20z"
      />
    </g>
  </svg>
);

export default JerseySponsoLogo;
